<div class="home_Slider">
    <div class="owlSlider owl-carousel owl-theme">
        <div *ngFor="let BannerItem of Banner" class="item">
          <span class="web">
            <a href="{{BannerItem.value}}" ><img src="{{api.mediaURL}}{{BannerItem.banner}}"></a>
          </span>
          <span class="mob">
            <a href="{{BannerItem.value}}" ><img src="{{api.mediaURL}}{{BannerItem.banner1}}"></a>
          </span>
        </div>
    </div>
</div>
<!-- <app-home-search></app-home-search> -->
<div class="container">
  <div class="top-search-bar"> 
      <div class="widget">
          <div class="ng-autocomplete"> 
              <select (change)="changeLocation($event.target.value)" class="form-control Drpcss1 form-select" [(ngModel)]="LabLocd">
                  <option *ngIf="LabLocd===''" value="" disabled selected >Select Location</option>
                  <option *ngFor="let labLoc of LabLocationlist" value="{{labLoc.lab_id}}" >{{labLoc.name}} </option> 
              </select>
          </div>
      </div>
      <div class="top-search-field">
        <ng-multiselect-dropdown class="form-control Drpcss" [placeholder]="util.translate('Search Test Name')" maxHeight="100"
            [data]="getList()" (onSelect)="onItemSelect($event)" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (input)="onKeydownEvent($event.target.value)" >
        </ng-multiselect-dropdown>
                         <!-- <div *ngFor="let TestItem of LabTestinfo" > 
                            <input (change)="onItemChange($event.target.checked, TestItem.test_id)" [checked]="checked(TestItem.test_id)" type="checkbox" value="{{TestItem.test_id}}" > {{TestItem.name}}
                        </div> --> 
        <!-- <input type="search" placeholder="Enter test name" class="form-control"></div> -->
   </div>
</div>
<!-- <app-thumbnail-banner></app-thumbnail-banner> -->
<div class="thumbBannerSection">
  <div class="container">
      <div class="row" *ngIf="HomePanel01?.length">
        
          <div class="col-md-3 BoxFit" *ngFor="let HpItem of HomePanel01" >  <!-- (click)="goToHomePanel01(HpItem)" -->
              <div class="banner">
                  <div class="img-container">
                      <img src="{{api.mediaURL}}{{HpItem.banner}}" alt="">
                  </div>
                  <div class="content">
                      <h5><br><br></h5>
                      <a href="{{HpItem.link_url}}" class="btn btn-primary-sm">{{HpItem.btn_cap}}</a>
                  </div>
              </div>
          </div>
           
      </div>
  </div>
</div>


<div class="bluebg">
  <div class="container">
    <div class="row">
        <div class="col-md-7">
            <h2><p>Get an Instant Callback</p></h2>
        </div> 
        <div class="col-md-5">
            <input class="cbinput" type="number" appOnlynumber [(ngModel)]="mobilenumb" [placeholder]="util.translate('Phone Number ')"  >
            <button class="btn btn-primary-sm btn_insta" (click)="phonecallback()"  >Call Now </button>
        </div>
    </div>
      
</div></div>



<!-- <app-disorder></app-disorder> -->

<!-- Lifecycle Disorder -->
<div class="lifecycle">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="header">
                  <h2>Lifestyle Disorders </h2>
                  <!-- <a href="/disorder" class="btn btn-primary-sm">View All</a> -->
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-12" *ngIf="Disorder?.length">
              <div class="lifecycle_Slider owl-carousel owl-theme">
                 
                   
                    <div class="item" *ngFor="let item of Disorder" > 
                      <a href="/disorder/{{item.Name}}">
                          <div class="diseas_img">
                              <img src="{{api.mediaURL}}{{item.Path}}">
                          </div>
                          <p>{{item.Name}}</p>
                       </a>
                    </div>  

              </div>
          </div>
      </div>
  </div>
</div>


<!-- Medical Profession -->
<div class="medical_profession">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="center-heading text-center mb-5">
                    <h5 class="sub-heading">Choose a Medical Professional</h5>
                    <h1 class="main-heading">Book Home Collection</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="home-card">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img src="assets/images/licensed-icon.png" alt="...">
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5>Convenient & Time Saving</h5>
                            We value your time and strive to provide convenient solutions to make your life easier.

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="home-card">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img src="assets/images/experienced-icon.png" alt="...">
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5>Free Home Collection & Cancellation</h5>
                            Our team of professionals will collect samples right from your doorstep, completely free of charge

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="home-card">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img src="assets/images/verified-icon.png" alt="...">
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5>Online Access to Reports
                            </h5>
                            We are committed to providing you with easy and convenient access to your reports online.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Advertisment -->
<div class="advertisement">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="banner-img">
                    <img src="assets/images/thumbbanner.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-package></app-package> -->
<!-- Health Package -->
<div class="health_package">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="center-heading mb-3">
                  <h5 class="sub-heading font28 w600" style="float: left">Health Packages Test</h5> 
                  <a href="/labtest" class="btn btn-primary-sm" style="float: right" > View All &nbsp;<i class="fa fa-arrow-circle-right"></i></a>
              </div>
              
          </div>
      <div class="row ">
          <div class="col-md-12 ">
              <div class="healthSlider owl-carousel owl-theme">
                  <div *ngFor="let LabTestsItem of LabTests;let i = index;" class="item">
                      <div class="health_card">
                        <div>
                          <h4>{{LabTestsItem.name}}</h4>
                          <div class="test_body">
                            <!-- <p class=""><i class=" fa fa-exclamation-circle pr-10"></i>No special preparation required</p> -->
                            <p class=""><i class="fa fa-cog pr-10"></i>Parameter(s) Covered : <span>{{LabTestsItem.parameters_count}}</span></p>
                            <p class=""><i class="fa fa-file-text pr-10"></i>Report : <span>{{LabTestsItem.tat}} Hrs.</span></p>
                            <p>₹ {{LabTestsItem.test_rate}}</p>
                          </div>
                        </div>
                          <div class=""> 
                              
                              <a class="pac_details_view" href="/labtest/{{getURLTxt(LabTestsItem.name)}}/{{LabTestsItem.test_id}}" > View Detail </a>   <!-- (click)="testDetails(item.id)" -->
                          </div>
                          <!-- cart -->
                          <div class="addToCart">
                          <!-- <a data-toggle="modal" data-target=".cart-success" class="btn btn-primary" *ngIf="!isInCart(i)" (click)="add(i)" id="a{{i}}">Add</a>
                          <a data-toggle="modal" data-target=".cart-success" class="btn btn-primary"  style="display:none;" (click)="removeQ(i)" id="r{{i}}" >Remove</a>   
                    -->

                          
                              <img src="assets/images/cart-add-icon.png" data-toggle="modal" data-target=".cart-success" (click)="add(i)" id="a{{i}}" >
                          </div>
                      </div>
                  </div>
                   
              </div>
          </div>
      </div>
  </div>
</div>


<!-- company feature -->
<div class="company-features">
    <div class="container">
       <div class="row">
          <div class="col-md-3"> 
             <h2>Why Choose Medibird Diagnostics ?</h2>
          </div>
          <div class="col-md-9">
             <ul> 
                <li>Home Sample Collection</li>
                <li>24 Hours Report</li>
                <li>Most Comprehensive Test Facility</li>
                <li>Expert Doctors For Audit</li>
                <li>Urgent Report Processing Available</li>
                <li>Highly Affordable</li>
             </ul> 
          </div>
       </div>
    </div>
 </div>
 <!-- Advertisment -->
<!-- <div class="advertisement second">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="banner-img">
                    <img src="assets/images/small-banner1.jpg" alt="">
                </div>
            </div>
            <div class="col-md-4">
                <div class="banner-img">
                    <img src="assets/images/small-banner2.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- blog and testimonial -->
<div class="medical_profession blog-testimonial">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="center-heading mb-5">
                    <h5 class="sub-heading font28 w600">From Our Blog</h5>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12" *ngFor="let BlogItem of WpBlogs" >
                        <div class="home-card">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                  <div class="banner-img">
                                    <img src="{{BlogItem._embedded['wp:featuredmedia']['0'].source_url}}" alt="" >
                                  </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span><i class="fa fa-calendar-check-o"></i> {{BlogItem.date | date: 'dd/MM/yyyy'}}</span>
                                        <a target="_blog" href='{{BlogItem.link}}'><h5>{{BlogItem.title.rendered}}</h5></a>
                                        <div [innerHTML]="BlogItem.content.rendered.slice(0, 150) "></div>  
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"><a href="https://medibird.com/blog/" target="_medibirdblog" class="btn btn-primary-sm">View All &nbsp;<i class="fa fa-arrow-circle-right"></i></a></div>
                </div>
            </div>
            <!-- testimonial -->
            <div class="col-md-6">
                <div class="testimonial">
                    <h5 class="mb-3">Testimonials </h5>
                    <div class="testimonialSlider owl-carousel owl-theme">
                        <div class="item">
                            <div class="testimony-wrap">
                                <div class="text">
                                    <div class="d-flex align-items-center mb-4">
                                        <div class="user-img" style="background-image: url(assets/images/dummy-img.jpg);background-size: cover; background-position: center;">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class=""><img src="assets/images/quote.png"></span>
                                            </div>
                                        </div>
                                        <div class="ps-3 tx">
                                            <p class="name">Ravi Chopra</p>
                                            <!-- <span class="position">Marketing Manager</span> -->
                                        </div>
                                    </div>
                                    <p class="mb-4 msg">Very hygienic and clean lab with highly competent and professional staff.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimony-wrap">
                                <div class="text">
                                    <div class="d-flex align-items-center mb-4">
                                        <div class="user-img" style="background-image: url(assets/images/dummy-img.jpg);background-size: cover; background-position: center;">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class=""><img src="assets/images/quote.png"></span>
                                            </div>
                                        </div>
                                        <div class="ps-3 tx">
                                            <p class="name">Ram Anuj Mishra</p>
                                            <!-- <span class="position">Marketing Manager</span> -->
                                        </div>
                                    </div>
                                    <p class="mb-4 msg">Fantastic service very friendly staff and clean facility. reliable and quick results.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                          <div class="testimony-wrap">
                              <div class="text">
                                  <div class="d-flex align-items-center mb-4">
                                      <div class="user-img" style="background-image: url(assets/images/dummy-img.jpg);background-size: cover; background-position: center;">
                                          <div class="icon d-flex align-items-center justify-content-center">
                                              <span class=""><img src="assets/images/quote.png"></span>
                                          </div>
                                      </div>
                                      <div class="ps-3 tx">
                                          <p class="name">Tiyanshi Kalsi</p>
                                          <!-- <span class="position">Marketing Manager</span> -->
                                      </div>
                                  </div>
                                  <p class="mb-4 msg">Excellent and helpful staff the assistance was courteous. The home sample collection service was fantastic.</p>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- 
<div class="row_div">
  <div class="row">
    <div class="col-lg-12 col-sm-4 back_image bg_image" [style.backgroundImage]="'url(assets/imgs/back1.jpg)'">
      <div class="overlay">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-xl-7 col-lg-8">
              <h1 class="bold_lbl">
                {{util.translate('Find Your Favourite')}} <br> {{util.translate('MediBird')}}
              </h1>
              <div class="row full_div container">
                <div class="col-8">
                  <div class="input_div">
                    <img src="assets/imgs/placeholder.png" style="width: 25px;height: 22px;">
                    <input type="text" style="font-size: 12px;" class="form-control" [(ngModel)]="autocomplete1.query"
                      [placeholder]="util.translate('Location')" (ngModelChange)="onSearchChange($event)">
                  </div>
                </div>
                <div class="col-4" *ngIf="util.deviceType ==='desktop'">
                  <div class="btns">
                    <div class="locate_btn waves-effect" mdbWavesEffect (click)="locate()">
                      <mdb-icon fas icon="location-arrow"></mdb-icon>
                      {{util.translate('Locate Me ')}}
                    </div>
                    <div class="arrow_btn" (click)="goToRest()"> <mdb-icon fas icon="search"> </mdb-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="util.deviceType !=='desktop'">
                <div class="col-12">
                  <div class="locate_btn_lng waves-effect" mdbWavesEffect (click)="locate()">
                    <mdb-icon fas icon="location-arrow"></mdb-icon>
                    {{util.translate('Locate Me')}}
                  </div>
                </div>
              </div>
              <div class="row address_list">
                <div class="col-12 address_name" *ngFor="let item of autocompleteItems1"
                  (click)="selectSearchResult1(item)">
                  <mdb-icon fas icon="map-marker-alt" style="color: gray; margin-right: 10px;"></mdb-icon>
                  {{ item.description }}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->

<!-- <div class="container service_div">
  <div class="row">
    <div class="col-lg-2 col-sm-4 col-md-4 service_box">
      <img src="assets/homeicons/breakfast.png" style="width: 110px;" alt="">
      <label class="small_lbl">{{util.translate('Breakfast')}}</label>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4 service_box">
      <img src="assets/homeicons/omelette.png" style="width: 110px;" alt="">
      <label class="small_lbl">{{util.translate('Dinner')}}</label>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4 service_box">
      <img src="assets/homeicons/lunch-box.png" style="width: 110px;" alt="">
      <label class="small_lbl">{{util.translate('Lunch')}}</label>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4 service_box">
      <img src="assets/homeicons/delivery.png" style="width: 110px;" alt="">
      <label class="small_lbl">{{util.translate('Delivery')}}</label>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4 service_box">
      <img src="assets/homeicons/nightlife.png" style="width: 110px;" alt="">
      <label class="small_lbl">{{util.translate('Nightlife')}}</label>
    </div>
    <div class="col-lg-2 col-sm-4 col-md-4 service_box">
      <img src="assets/homeicons/take-away.png" style="width: 110px;" alt="">
      <label class="small_lbl">{{util.translate('Take away')}}</label>
    </div>

  </div>
</div> -->

<!-- <div class="container">
  <div class="row getapp_div">
    <div class="col-lg-6 getapp_content">
      <label class="head_lbl">{{util.translate('Quality Indian cuisine at your door steps.')}}</label>
      <label class="small_lbl">
        {{util.translate('Choose from your favourite MediBird and get delivered to you. You can search MediBird near you and order food.')}}
      </label>
      <button mdbBtn class="round_btn" (click)="goToRest()">{{util.translate('Find MediBird')}}</button>
    </div>
    <div class="col-lg-6">
      <img src="assets/imgs/back3.jpg" style="width: 100%;">
    </div>
  </div>
</div>

<div style="background: #f5f5f5;padding-top: 60px;padding-bottom: 60px;margin-top: 60px;">
  <div class="container">
    <div class="row getapp_div">
      <div class="col-lg-6">
        <img src="assets/imgs/back4.jpg" style="width: 100%;">
      </div>
      <div class="col-lg-6 getapp_content">
        <label class="head_lbl">{{util.translate('You have got stuff to do. We have got options.')}}</label>
        <label class="small_lbl">
          {{util.translate("Git it delivered right to your door. Or, try Pickup on your way home. It's Meal time on your
          time")}}
        </label>
        <button mdbBtn class="round_btn" (click)="goToRest()">{{util.translate('Order Now')}}</button>
      </div>
    </div>
  </div>
</div>

<div class="container blog_container" *ngIf="blogs?.length">

  <label class="blog_header">{{util.translate('Our Blogs')}}</label>

  <div class="row product_div">
    <div class="pro_col col-lg-4 col-sm-12 col-md-6 col-xs-12" *ngFor="let item of blogs" (click)="goToBlogs(item)">
      <div class="pro_div hoverable">
        <div class="rest_img bg_image" [style.backgroundImage]="'url('+api.mediaURL+ item.cover +')'">
          <div class="overlay">
            <label class="date">{{getDate(item.published)}} </label>
            <label class="month">{{getMonth(item.published)}} </label>
          </div>
        </div>
        <div class="content_div">
          <label class="pro_name">{{ (item.title.length>20)? (item.title | slice:0:20)+'..':(item.title) }} </label>
          <label class="addr" [innerHTML]="getContent(item)">
          </label>
          <div class="service_flex">
            <label>{{util.translate('Read More')}}</label>
            <mdb-icon fas icon="arrow-right"></mdb-icon>
          </div>

        </div>
      </div>
    </div>
    <mdb-icon fas icon="long-arrow-alt-right" class="all_blog"></mdb-icon>
  </div>
</div> -->


<!-- <div style="background: #f5f5f5;padding-top: 60px;padding-bottom: 60px;">
  <div class="container">
    <div class="row getapp_div">
      <div class="col-lg-6" style="position: relative;">
        <img src="assets/imgs/screen.jpg" style="width: 60%;height: 95%;">
      </div>
      <div class="col-lg-6 getapp_content">
        <label class="head_lbl">{{util.translate('Try App')}}</label>
        <label class="small_lbl">
          {{util.translate('Get the best MediBird experience with live order tracking.')}}
        </label>

        <a [href]="util.google_playstore" target="_blank" rel="noopener noreferrer">
          <img src="assets/imgs/android.png" class="app_img hoverable">
        </a>
        <a [href]="util.apple_appstore" target="_blank" rel="noopener noreferrer">
          <img src="assets/imgs/ios.png" class="app_img hoverable">
        </a>

      </div>
    </div>
  </div>
</div> -->
